import { createGlobalStyle, css } from 'styled-components'
import { HEADER_FONT, SUBHEADER_FONT, NAV_FONT, SANS_SERIF } from './type'
import { color, media, layout } from './theme'
import { rem } from 'polished'
import { AppContext } from '../context/AppContext'
import React from 'react'

const GlobalStyle = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  h1,h2,h3 {
  font-family: ${HEADER_FONT}
  }
  h4,h5,h6 {
    font-family: ${SUBHEADER_FONT}
  }

  nav {
    font-family: ${NAV_FONT}
  }


  html {
    text-size-adjust: 100%;
  }

  /* Added to Fix Footer to bottom of viewport */
  html, body {
    padding: 0;
    margin: 0;

    @media (min-width: 769px) {
      overflow-x: hidden;
    }

    ${({ isScrollingLocked }) =>
      isScrollingLocked &&
      css`
        overflow: hidden;
        height: 100vh;
      `};
  }

  footer {
    width: 100%;
  }

  /* End Fix to Place Footer on Bottom of Viewport */

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }


  blockquote, q {
    quotes: none;
  }

  blockquote::before, blockquote::after,
  q::before, q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }

  body {
    background-color: #fff;
    line-height: 1;
    font-size: 100%;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    font-weight: 400;
    font-family: ${SANS_SERIF};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    -webkit-overflow-scrolling: touch;
    min-height: 100vh;

    img {
        display: block;
        // width: 100%;
        // height: auto;
      }

    > img {
      position: absolute;
      top: -1px;
    }
  }



  button,
  input {
    font-family: inherit;
    font-size: inherit;
    background: none;
    border: none;
    outline: none;
    appearance: none;
    border-radius: 0;
    resize: none;

    &:invalid {
      box-shadow: none;
    }
  }

  a:focus {
    outline: none;
    box-shadow: none;

    ${({ showFocus }) =>
      showFocus &&
      css`
        outline: 1px solid ${color.accentLightBlue};
        box-shadow: 0 0 5px ${color.accentLightBlue};
      `};
  }

  a {
    text-decoration: none;
  }

  #___gatsby {
    height: 100%;
    z-index: 0;
    min-height: 100vh;

    > div {
      height: 100%;
      min-height: 100vh;
    }
  }

  #portal {
    position: absolute;
    top: 0;
    left: 0;
  }

  #fixed-portal {
    position: fixed;
    top: 0;
    left: 0;
  }

  #results-container-portal {
    width: 100%;
    position: absolute;
    top: ${rem(layout.navHeight.xl)};
    z-index: 99999;

    ${media.xl`
      top: ${rem(layout.navHeight.xl)};
    `}
  }

  #hint-results-portal, #hint-results-portal-mobile  {
      z-index: 999999;
      position: absolute;
      width: 100%;
  }

  #hint-results-portal-mobile {
    top: ${rem(60)};
    background-color: #F3F5F7;
    min-height: calc(100vh - 170px);
  }

  [style*="--aspect-ratio"] > :first-child {
    width: 100%;
  }

  @supports (--custom:property) {
    [style*="--aspect-ratio"] {
      position: relative;
    }
    [style*="--aspect-ratio"]::before {
      height: 0;
      content: "";
      display: block;
      padding-bottom: calc(100% / (var(--aspect-ratio)));
    }
    [style*="--aspect-ratio"] > :first-child {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }

  #loading-screen {
    pointer-events: ${({ loaded }) => (loaded ? 'none' : 'auto')};
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: white;
    z-index: 10000000;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    transition: opacity .4s;
    opacity: ${({ loaded }) => (loaded ? 0 : 1)};
  }

   .at-share-btn-elements {
      a {
        &:after {
          content: none!important;
        }
      }
    }

    .at-icon-wrapper {
      width: 25px!important;
      height: 25px!important;
      svg {
        width: 100%!important;
        height: 100%!important;
      }
    }

`
export default (
  { loaded }, // eslint-disable-line
) => (
  <AppContext.Consumer>
    {({ isScrollingLocked, focusStyleOn }) => (
      <GlobalStyle
        isScrollingLocked={isScrollingLocked}
        showFocus={focusStyleOn}
        loaded={loaded}
      />
    )}
  </AppContext.Consumer>
)
