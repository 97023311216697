import PropTypes from 'prop-types'

import {
  SANS_SERIF,
  WEIGHT,
  NAV_FONT,
  HEADER_FONT,
  SUBHEADER_FONT,
} from '../../style/type'

import styled, { css } from 'styled-components'
import { get, isNumber } from 'lodash'
import { rem, transparentize } from 'polished'
import { fontSizes, fontSizesSmall } from '../../style/theme'
import { buttonStyle } from '../common/Button'
import { motion } from 'framer-motion'

import list from '../../assets/list.svg'
import quotes from '../../assets/quote.svg'

export const sizes = fontSizes
const fontKeys = Object.keys(fontSizes)

export const setFontSize = (size, ratio) => css`
  font-size: ${rem(size)};

  ${ratio &&
    css`
      line-height: ${ratio};
    `};
`

const setSize = (size, ratio) =>
  isNumber(size)
    ? setFontSize(size, ratio)
    : setFontSize(fontSizes[size], ratio)

const article = ({ theme }) => css`
  ${setSize(fontSizesSmall.lg, 1.7779)};
  font-weight: ${WEIGHT.BOOK};

  ${theme.media.lg`
            ${setSize(sizes.lg, 1.7779)};
          `};

  letter-spacing: 0;

  li {
    line-height: ${rem(32)};
  }
`

const Text = styled.div`
  ${({
    black,
    bold,
    book,
    center,
    color,
    ellipsis,
    html,
    htmlMargin,
    italic,
    lineHeight,
    lineHeightMultiplier,
    lineHeightLg,
    linkColor,
    medium,
    nowrap,
    opacity,
    overflowWrap,
    right,
    selectNone,
    size,
    spacing,
    spacingLg,
    theme,
    white,
    wysiwyg,
    uppercase,
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
  }) => css`
    position: relative;

    ${setSize(size, lineHeightMultiplier)};
    font-weight: ${WEIGHT.BOOK};
    margin: 0;

    color: ${theme.color.neutralBlue};
    text-align: left;
    z-index: 1;

    i,
    em {
      font-style: italic;
    }

    b,
    strong {
      font-weight: ${WEIGHT.BOLD};
    }

    /* Override */
    ${book &&
      css`
        font-weight: ${WEIGHT.BOOK};
      `};

    ${bold &&
      css`
        font-weight: ${WEIGHT.BOLD};
      `};

    ${black &&
      css`
        font-weight: ${WEIGHT.BLACK};
      `};

    ${center &&
      css`
        text-align: center;
      `};

    ${color &&
      css`
        color: ${get(theme.color, color) || color};
      `};

    ${ellipsis &&
      css`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `};

    ${italic &&
      css`
        font-style: italic;
      `};

    ${medium &&
      css`
        font-weight: ${WEIGHT.MEDIUM};
        font-family: ${NAV_FONT};
      `};

    ${nowrap &&
      css`
        white-space: nowrap;
      `};

    ${opacity &&
      css`
        opacity: ${opacity};
      `};

    ${lineHeight &&
      css`
        line-height: ${lineHeight};
      `};

    ${right &&
      css`
        text-align: right;
      `};

    ${selectNone &&
      css`
        user-select: none;
      `};

    ${spacing &&
      css`
        letter-spacing: ${!isNaN(spacing) ? rem(spacing) : spacing};
      `};

    ${white &&
      css`
        color: #fff;
      `};

    ${uppercase &&
      css`
        text-transform: uppercase;
      `};

    ${overflowWrap &&
      css`
        overflow-wrap: ${overflowWrap};
      `};

    /* Media queries */

    ${xs &&
      theme.media.xs`
        ${setSize(xs, lineHeightMultiplier)};
      `};

    ${sm &&
      theme.media.sm`
        ${setSize(sm, lineHeightMultiplier)};
      `};

    ${md &&
      theme.media.md`
         ${setSize(md, lineHeightMultiplier)};
      `};

    ${lg &&
      theme.media.lg`
         ${setSize(lg, lineHeightMultiplier)};
      `};

    ${xl &&
      theme.media.xl`
         ${setSize(xl, lineHeightMultiplier)};
      `};

    ${xxl &&
      theme.media.xxl`
         ${setSize(xxl, lineHeightMultiplier)};
      `};

    ${lineHeightLg &&
      theme.media.lg`
         line-height: ${lineHeightLg} !important;
      `};

    ${spacingLg &&
      theme.media.lg`
         letter-spacing: ${!isNaN(spacingLg) ? rem(spacingLg) : spacingLg};
      `};

    a,
    a:active,
    a:visited {
      color: ${linkColor
        ? get(theme.color, linkColor) || color
        : 'currentColor'};
      text-decoration: none;
      transition: opacity 0.4s ease-in;
    }

    img {
      width: 100%;
      height: auto;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      margin-left: ${theme.space(2)};
      line-height: 1.4;
    }

    ${html &&
      css`
        h1,
        h2,
        h3 {
          font-family: ${HEADER_FONT};
        }
        h4,
        h5,
        h6 {
          font-family: ${SUBHEADER_FONT};
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: ${theme.color.blue};
          text-transform: uppercase;
        }

        h1 {
          ${setSize(fontSizesSmall.xxxxl, 1.2)};

          ${theme.media.lg`
            ${setSize('xxxxl', 54 / 46)};
          `};
        }

        h2 {
          ${setSize(fontSizesSmall.xxxl, 32 / 26)};

          ${theme.media.lg`
            ${setSize('xxxl', 1.32)};
          `};
        }

        h3 {
          ${setSize(fontSizesSmall.xxl, 1.35)};

          ${theme.media.lg`
            ${setSize('xxl', 1.35)};
          `};

          letter-spacing: ${rem(1.5)};
        }

        h4 {
          ${setSize(fontSizesSmall.xl, 1.37)};

          ${theme.media.lg`
            ${setSize('xl', 28 / 20)};
          `};

          letter-spacing: ${rem(1)};
        }

        h5 {
          font-weight: ${WEIGHT.MEDIUM};

          ${setSize(fontSizesSmall.sm, 1.5)};

          ${theme.media.lg`
            ${setSize('sm', 1.6)};
          `};

          letter-spacing: ${rem(1.2)};
        }

        h6 {
          ${setSize(fontSizes.xxs, 1.3)};
          letter-spacing: ${rem(0.5)};
        }

        p {
          ${setSize(fontSizesSmall.md, 1.48)};
          font-weight: ${WEIGHT.BOOK};

          ${theme.media.lg`
            ${setSize('md', 25 / 15)};
          `};

          > button {
            margin-bottom: ${theme.space(2)};

            ${theme.media.sm`
              margin-right: ${theme.space(2)};
            `};
          }

          &:not(:last-child) {
            margin-bottom: ${theme.space(2)};
          }

          letter-spacing: 0;
        }

        ul {
          list-style-image: url(${list});
        }

        li {
          ${setSize(fontSizesSmall.md, 1.48)};
          font-weight: ${WEIGHT.BOOK};

          ${theme.media.lg`
            ${setSize('md', 25 / 15)};
          `};

          padding-left: ${theme.space(0.5)};
        }

        i,
        em {
          font-style: italic;
        }

        a,
        a:active,
        a:visited {
          position: relative;
          color: ${theme.color.secondaryLightBlue};
          font-weight: ${WEIGHT.MEDIUM};
          text-transform: uppercase;
          white-space: nowrap;

          &:after {
            position: absolute;
            display: block;
            content: '';
            width: 100%;
            height: 2px;
            left: 0;
            bottom: -4px;
            background-color: ${theme.color.yellow};
            transition: 0.3s background;
          }

          &:hover {
            &:after {
              background-color: #fff;
            }
          }
        }

        blockquote {
          ${setSize(fontSizesSmall.xl, 1.4)};
          color: ${theme.color.blue};
          font-weight: ${WEIGHT.BOLD};
          font-style: italic;
          letter-spacing: 0;
          max-width: ${rem(570)};

          ${theme.media.md`
            float: right;
            width: calc(50% - ${theme.space(2)});
            margin: ${theme.space(2)};
            padding-right: 0;
        `};

          ${theme.media.lg`
            ${setSize('xxl', 1.34)};
            border-left: 2px solid ${theme.color.secondaryLightBlue};
            padding: 0 ${theme.space(3)};
          `};

          margin: ${theme.space(2)} 0;

          &:before {
            display: block;
            content: '';
            width: 18px;
            height: 12px;
            margin-bottom: ${theme.space(2)};

            background-size: 18px 12px;
            background-image: url(${quotes});
            background-repeat: no-repeat;

            ${theme.media.lg`
            background-size: 30px 21px;
              width: 30px;
              height: 21px;
            `};
          }
        }

        cite {
          display: block;
          color: ${theme.color.secondaryBlue};
          ${setSize('xs', 1.4)};
          font-weight: ${WEIGHT.MEDIUM};
          font-style: normal;
          text-transform: uppercase;
          line-height: 1.8;
          margin-top: ${theme.space(2)};

          ${theme.media.md`
            margin-top: ${theme.space(3)};
          `};
        }

        .paragraph {
          ${article};
        }

        .primary,
        .secondary {
          ${buttonStyle};

          margin-top: ${theme.space(3)};
          margin-right: ${theme.space(3)};

          font-size: ${rem(11)};
          font-weight: ${WEIGHT.BOLD};
          letter-spacing: 0.03125rem;
          line-height: ${rem(20)};

          ${theme.media.sm`
              display: inline-flex;
              flex: 0;
              width: auto;
              min-width: ${rem(150)};
            `};

          &:visited {
            color: ${theme.color.blue};
          }
        }

        .secondary {
          background-color: transparent;
          border: 2px solid ${theme.color.blue};

          &:hover {
            color: #fff;
            background-color: ${theme.color.blue};
          }
        }

        .cta {
          color: currentColor;
          ${setSize('xs', 1.4)};

          &:after {
            display: none !important;
          }
        }

        .micro {
          ${setSize('xs', 1.4)};
        }
      `};

    ${htmlMargin &&
      css`
        > * {
          margin: ${theme.space(1)} 0;

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin: ${theme.space(2)} 0 ${theme.space(1)};

          ${theme.media.lg`
            margin: ${theme.space(3)} 0 ${theme.space(2)};
          `};
        }

        h3 {
          margin-bottom: ${theme.space(3)};

          ${theme.media.lg`
             margin-bottom: ${theme.space(4)};
          `};
        }

        a,
        a:active,
        a:visited {
          color: ${theme.color.secondaryBlue};
          font-weight: ${WEIGHT.MEDIUM};
          text-transform: none;

          &:after {
            background-color: ${theme.color.secondaryLightBlue};
          }

          &:hover {
            &:after {
              background-color: ${theme.color.accentBlue};
            }
          }
        }
      `};

    ${wysiwyg && article};
  `};
`

Text.propTypes = {
  black: PropTypes.bool,
  bold: PropTypes.bool,
  center: PropTypes.bool,
  children: PropTypes.node,
  color: PropTypes.string,
  ellipsis: PropTypes.bool,
  html: PropTypes.bool,
  htmlMargin: PropTypes.bool,
  italic: PropTypes.bool,
  lineHeight: PropTypes.number,
  lineHeightMultiplier: PropTypes.number,
  lineHeightLg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  linkColor: PropTypes.string,
  medium: PropTypes.bool,
  nowrap: PropTypes.bool,
  right: PropTypes.bool,
  selectNone: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // rem || 'normal'
  spacingLg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // rem || 'normal'
  theme: PropTypes.object,
  white: PropTypes.bool,
  wysiwyg: PropTypes.bool,
  uppercase: PropTypes.bool,
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  xxl: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
}

Text.defaultProps = {
  lineHeight: 1.7,
  lineHeightMultiplier: 1.48,
  size: fontSizes.md,
}

const headingProps = {
  lineHeightMultiplier: 1.2,
  bold: true,
  color: 'blue',
  uppercase: true,
}

Text.h1 = Text.withComponent('h1')
Text.h1.defaultProps = {
  ...headingProps,
  size: fontSizesSmall.xxxxl,
  lg: 'xxxxl',
  lineHeight: 1.2,
  lineHeightMultiplier: 54 / 46,
}

Text.h2 = Text.withComponent('h2')
Text.h2.defaultProps = {
  ...headingProps,
  size: fontSizesSmall.xxxl,
  lg: 'xxxl',
  lineHeightMultiplier: 32 / 26,
  lineHeightLg: 1.3125,
}

Text.h3 = Text.withComponent('h3')
Text.h3.defaultProps = {
  ...headingProps,
  size: fontSizesSmall.xxl,
  lg: 'xxl',
  lineHeightMultiplier: 1.35,
  spacing: 1.5,
}

Text.h4 = Text.withComponent('h4')
Text.h4.defaultProps = {
  ...headingProps,
  bold: true,
  size: fontSizesSmall.xl,
  lg: 'xl',
  spacing: 1,
  lineHeightMultiplier: 1.37,
  lineHeightLg: 28 / 20,
  overflowWrap: 'normal',
}

Text.h5 = Text.withComponent('h5')
Text.h5.defaultProps = {
  ...headingProps,
  bold: false,
  medium: true,
  size: fontSizesSmall.sm,
  lg: 'sm',
  spacing: 1.2,
  lineHeightMultiplier: 1.5,
  lineHeightLg: 1.6,
  overflowWrap: 'normal',
}

// Alternative style for h5 from comps
Text.h5Alt = Text.withComponent('h5')
Text.h5Alt.defaultProps = {
  ...headingProps,
  bold: true,
  size: 14,
  lg: 14,
  spacing: 1.2,
  lineHeightMultiplier: 1.6,
}

Text.h6 = Text.withComponent('h6')
Text.h6.defaultProps = {
  ...headingProps,
  bold: true,
  size: 'xxs',
  lineHeightMultiplier: 1.3,
  spacing: 0.5,
}

Text.p = Text.withComponent('p')
Text.p.defaultProps = {
  size: fontSizesSmall.md,
  lg: 'md',
  lineHeightMultiplier: 1.48,
  lineHeightLg: 25 / 15,
  spacing: 0,
}

Text.span = Text.withComponent('span')

Text.blockquote = Text.withComponent('blockquote')
Text.blockquote.defaultProps = {
  lineHeightMultiplier: 1.4,
  bold: true,
  color: 'blue',
  italic: true,
  size: fontSizesSmall.xl,
  lg: 'xxl',
  spacing: 0,
}

Text.cite = Text.withComponent('blockquote')
Text.blockquote.defaultProps = {
  medium: true,
  size: 'xs',
}

Text.cite = Text.withComponent('cite')

Text.article = Text.withComponent('article')

Text.motion = Text.withComponent(motion.div)

export default Text
