// packages/gatsby-site/src/components/slices/PopupModule.js
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

const PopupModule = ({ slice_type, primary }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      const dialog = document.querySelector('dialog')
      if (dialog) {
        dialog.showModal()
        dialog.querySelector('.close').addEventListener('click', () => {
          dialog.close()
        })
      }
    }, 3500)

    //clean up after unmount
    return () => clearTimeout(timer)
  }, [])

  const adImage = get(primary, 'popup_image')
  const adLink = get(primary, 'popup_link')
  const borderColor = get(primary, 'border_color', 'Midnight Blue Dark')
  const popupPosition = get(primary, 'popup_position', 'Center')
  const borderWidth = get(primary, 'border_width_px', '2')

  let positionStyle = {}

  let positionStyleBottomRight = {
    transform: 'translateY(-50%)',
    marginRight: 0,
    top: '100%',
  }

  let positionStyleTopLeft = {
    margin: 0,
  }

  switch (popupPosition) {
    case 'Bottom Right':
      positionStyle = positionStyleBottomRight
      break
    case 'Top Left':
      positionStyle = positionStyleTopLeft
      break
    default:
      positionStyle = {}
  }

  let borderColorHex = '#072584'

  switch (borderColor) {
    case 'Midnight Blue Dark':
      borderColorHex = '#072584'
      break
    case 'Midnight Blue Light':
      borderColorHex = '#114cff'
      break
    case 'Sunlight Yellow Dark':
      borderColorHex = '#ffaf05'
      break
    case 'Sunlight Yellow Light':
      borderColorHex = '#ffff00'
      break
    case 'Bright Blue Dark':
      borderColorHex = '#0032ff'
      break
    case 'Bright Blue Light':
      borderColorHex = '#32d8ff'
      break
    case 'Purple Dark':
      borderColorHex = '#1900ff'
      break
    case 'Purple Light':
      borderColorHex = '#9900ff'
      break
    case 'Red Dark':
      borderColorHex = '#5b0000'
      break
    case 'Red Light':
      borderColorHex = '#ff0000'
      break
    case 'Green Dark':
      borderColorHex = '#0f7a00'
      break
    case 'Green Light':
      borderColorHex = '#4ce54c'
      break
    case 'Teal Dark':
      borderColorHex = '#15826c'
      break
    case 'Teal Light':
      borderColorHex = '#4cffb2'
      break
  }

  return (
    <div>
      <dialog
        style={{
          padding: '0',
          border: `${borderWidth}px solid ${borderColorHex}`,
          boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.4)',
          ...positionStyle,
        }}
      >
        <button className="close" style={{ float: 'right', fontSize: '24px' }}>
          x
        </button>
        <form method="dialog">
          <a href={adLink.url}>
            <img src={adImage.url} alt={adImage.alt} />
          </a>
        </form>
      </dialog>
      <style jsx>{`
        dialog::backdrop {
          background: rgba(0, 0, 0, 0.75); /* A 50% black overlay */
        }
      `}</style>
    </div>
  )
}

PopupModule.propTypes = {
  slice_type: PropTypes.string,
  primary: PropTypes.object,
}

export default PopupModule
