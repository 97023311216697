export const ARTICLE_MODULE = 'article_module'
export const CASINO_HOST_MODULE = 'casino_host_module'
export const DINING_GALLERY_MODULE = 'dining_gallery_module'
export const DINING_ITEM_MODULE = 'dining_item_module'
export const DINING_LIST_MODULE = 'dining_list_module'
export const EMBED_MODULE = 'embed_module'
export const END_UP_PAGE_MODULE = 'end_up_page_module'
export const FAQ_MODULE = 'faq_module'
export const FEATURED_HERO_SLIDER_MODULE = 'featured_hero_slider_module'
export const GOOGLE_MAP_EMBED_MODULE = 'google_map_embed_module'
export const GRID_MODULE = 'grid_module'
export const TAG_GRID_MODULE = 'tag_grid_module'
export const HOTEL_BOOKING_MODULE = 'hotel_booking_module'
export const IMAGE_CTA_CARD_MODULE = 'image_cta_card_module'
export const IMAGE_GALLERY_MODULE = 'image_gallery_module'
export const INLINE_IMAGE_MODULE = 'inline_image_module'
export const INSTAGRAM_MODULE = 'instagram_module'
export const INTRO_MODULE = 'intro_module'
export const FULLBLEED_INTRO_MODULE = 'fullbleed_intro_module'
export const LISTING_MODULE = '3up_listing_module'
export const LOGO_SOUP_MODULE = 'logo_soup_module'
export const MENU_SECTION_TITLE_MODULE = 'menu_section_title_module'
export const MENU_ITEM = 'menu_item'
export const POST_WYSIWYG_MODULE = 'post_wysiwyg'
export const POST_RESULTS_MODULE = 'results_module'
export const RUSH_REWARDS_MATRIX = 'rush_rewards_matrix_module'
export const SECTION_TITLE_MODULE = 'section_title_module'
export const STAGGERED_TEXT_MODULE = 'staggered_text_module'
export const TEXT_IMAGE_MODULE = 'text_image_module'
export const TICKER_MODULE = 'ticker_module'
export const TWITTER_MODULE = 'twitter_module'
export const WIDE_WYSIWYG_MODULE = 'wide_wysiwyg_module'
export const VIDEO_MODULE = 'video_module'
export const FEATURE_MODULE_2_UP = '2_up_feature_module'
export const FEATURE_MODULE_3_UP = '3_up_feature_module'
export const FEATURE_MODULE_4_UP = '4_up_feature_module'
export const SIGN_UP_BAR = 'sign_up_bar'
export const BLOG_GRID_MODULE = 'blog_grid_module'
export const CALENDAR_MODULE = 'calendar_module'
export const TOURNAMENT_CALENDAR_MODULE = 'tournament_calendar_module'
export const POPUP_MODULE = 'popup_module'
