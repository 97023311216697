import {
  ARTICLE_MODULE,
  CASINO_HOST_MODULE,
  DINING_ITEM_MODULE,
  EMBED_MODULE,
  END_UP_PAGE_MODULE,
  FAQ_MODULE,
  FEATURED_HERO_SLIDER_MODULE,
  GOOGLE_MAP_EMBED_MODULE,
  GRID_MODULE,
  TAG_GRID_MODULE,
  HOTEL_BOOKING_MODULE,
  IMAGE_CTA_CARD_MODULE,
  IMAGE_GALLERY_MODULE,
  INLINE_IMAGE_MODULE,
  INTRO_MODULE,
  FULLBLEED_INTRO_MODULE,
  INSTAGRAM_MODULE,
  LISTING_MODULE,
  LOGO_SOUP_MODULE,
  POST_WYSIWYG_MODULE,
  POST_RESULTS_MODULE,
  RUSH_REWARDS_MATRIX,
  SECTION_TITLE_MODULE,
  STAGGERED_TEXT_MODULE,
  TEXT_IMAGE_MODULE,
  TICKER_MODULE,
  TWITTER_MODULE,
  WIDE_WYSIWYG_MODULE,
  VIDEO_MODULE,
  FEATURE_MODULE_2_UP,
  FEATURE_MODULE_3_UP,
  FEATURE_MODULE_4_UP,
  SIGN_UP_BAR,
  BLOG_GRID_MODULE,
  CALENDAR_MODULE,
  TOURNAMENT_CALENDAR_MODULE,
  POPUP_MODULE,
} from '../../constants/sliceTypes'

import ArticleModule from './ArticleModule'
import CasinoHostModule from './CasinoHostModule'
import CalendarModule from './CalendarModule'
import DiningItemModule from './DiningItemModule'
import EmbedModule from './EmbedModule'
import EndUpPageModule from './EndUpPageModule'
import FaqModule from './FaqModule'
import FeaturedHeroSliderModule from './FeaturedHeroSliderModule'
import GoolgeMapEmbedModule from './GoolgeMapEmbedModule'
import GridModule from './GridModule'
import ImageCtaCardModule from './ImageCtaCardModule'
import ImageGalleryModule from './ImageGalleryModule'
import HotelBookingModule from './HotelBookingModule'
import InlineImageModule from './InlineImageModule'
import IntroModule from './IntroModule'
import FullbleedIntroModule from './FullbleedIntroModule'
import InstagramModule from './InstagramModule'
import ListingModule from './ListingModule'
import LogoSoupModule from './LogoSoupModule'
import PostWysiwygModule from './PostWysiwygModule'
import PostResultsModule from './PostResultsModule'
import RushRewardsMatrixModule from './RushRewardsMatrixModule'
import SectionTitleModule from './SectionTitleModule'
import StaggeredTextModule from './StaggeredTextModule'
import TextImageModule from './TextImageModule'
import TickerModule from './TickerModule'
import TwitterModule from './TwitterModule'
import WideWysiwygModule from './WideWysiwygModule'
import VideoModule from './VideoModule'
import TagGridModule from './TagGridModule'
import NUpFeatureModule from './NUpFeatureModule'
import SignUpModule from './SignUpModule'
import BlogGridModule from './BlogGridModule'
import TournamentCalendarModule from './TournamentCalendarModule'
import PopupModule from './PopupModule'

export default {
  [FEATURE_MODULE_2_UP]: NUpFeatureModule,
  [FEATURE_MODULE_3_UP]: NUpFeatureModule,
  [FEATURE_MODULE_4_UP]: NUpFeatureModule,
  [ARTICLE_MODULE]: ArticleModule,
  [CASINO_HOST_MODULE]: CasinoHostModule,
  [DINING_ITEM_MODULE]: DiningItemModule,
  [EMBED_MODULE]: EmbedModule,
  [END_UP_PAGE_MODULE]: EndUpPageModule,
  [FAQ_MODULE]: FaqModule,
  [FEATURED_HERO_SLIDER_MODULE]: FeaturedHeroSliderModule,
  [GOOGLE_MAP_EMBED_MODULE]: GoolgeMapEmbedModule,
  [GRID_MODULE]: GridModule,
  [TAG_GRID_MODULE]: TagGridModule,
  [HOTEL_BOOKING_MODULE]: HotelBookingModule,
  [IMAGE_CTA_CARD_MODULE]: ImageCtaCardModule,
  [IMAGE_GALLERY_MODULE]: ImageGalleryModule,
  [INLINE_IMAGE_MODULE]: InlineImageModule,
  [INTRO_MODULE]: IntroModule,
  [FULLBLEED_INTRO_MODULE]: FullbleedIntroModule,
  [INSTAGRAM_MODULE]: InstagramModule,
  [LISTING_MODULE]: ListingModule,
  [LOGO_SOUP_MODULE]: LogoSoupModule,
  [POST_WYSIWYG_MODULE]: PostWysiwygModule,
  [POST_RESULTS_MODULE]: PostResultsModule,
  [RUSH_REWARDS_MATRIX]: RushRewardsMatrixModule,
  [SECTION_TITLE_MODULE]: SectionTitleModule,
  [STAGGERED_TEXT_MODULE]: StaggeredTextModule,
  [TEXT_IMAGE_MODULE]: TextImageModule,
  [TICKER_MODULE]: TickerModule,
  [TWITTER_MODULE]: TwitterModule,
  [WIDE_WYSIWYG_MODULE]: WideWysiwygModule,
  [VIDEO_MODULE]: VideoModule,
  [SIGN_UP_BAR]: SignUpModule,
  [BLOG_GRID_MODULE]: BlogGridModule,
  [CALENDAR_MODULE]: CalendarModule,
  [TOURNAMENT_CALENDAR_MODULE]: TournamentCalendarModule,
  [POPUP_MODULE]: PopupModule,
}
