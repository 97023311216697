import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Text from './Text'

export const isExternal = url =>
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+|^(tel|mailto):[A-Za-z0-9@\-\.]*$|data:\w*\//i.test(
    url,
  )

const LinkComponent = ({
  children,
  href,
  target,
  rel,
  className,
  ...props
}) => {
  const hrefClean = !href ? '#' : href
  const isBlank =
    target === '_blank' ? { without: true, rel: 'noopener noreferrer' } : {}

  return isExternal(hrefClean) ? (
    <a
      href={hrefClean}
      target={target}
      rel={rel}
      className={className}
      {...isBlank}
      {...props}
    >
      {children.props && children.props.className === 'primary' ? (
        <Text color="blue" bold>
          {children.props && children.props.href
            ? children.props.children
            : children}
        </Text>
      ) : (
        <>
          {children.props && children.props.href
            ? children.props.children
            : children}
        </>
      )}
    </a>
  ) : (
    <Link to={hrefClean} className={className} {...props}>
      {children !== null &&
      children.props &&
      children.props.className === 'primary' ? (
        <Text color="blue" bold>
          {children.props && children.props.href
            ? children.props.children
            : children}
        </Text>
      ) : (
        <>
          {children.props && children.props.href
            ? children.props.children
            : children}
        </>
      )}
    </Link>
  )
}

export default LinkComponent

LinkComponent.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  className: PropTypes.string,
}
