export const SANS_SERIF = `"GilroyMedium", "Gotham A", "Gotham B", Gotham, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Arial,
    sans-serif`

export const WEBSITE_HEADER_FONT = `"GilroyBold"`
export const NAV_FONT = `"GilroySemiBold"`
export const HEADER_FONT = `"GilroyExtraBold"`
export const SUBHEADER_FONT = `"GilroySemiBold"`
export const BUTTON_FONT = `"GilroyBold"`

export const WEIGHT = {
  BLACK: 700,
  BOLD: 600,
  MEDIUM: 500,
  BOOK: 400,
  LIGHT: 300,
}
